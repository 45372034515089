import { createContext, useContext, useState } from 'react';
const FooterMessageDataContext = createContext();

export default function FooterMessageDataProvider({ children }) {
  const [footerMessageData, setFooterMessageData] = useState()
  return <>
    <FooterMessageDataContext.Provider value={ { footerMessageData, setFooterMessageData } }>
      {children}
    </FooterMessageDataContext.Provider>
  </>;
}

export function useFooterMessageData() {
  const { footerMessageData, setFooterMessageData } = useContext(FooterMessageDataContext)
  return { footerMessageData, setFooterMessageData }
}
