import { createContext, useContext, useState } from 'react';
const CategoriesDataContext = createContext();

export default function CategoriesDataProvider({ children }) {
  const [categoriesData, setCategoriesData] = useState([])
  return <>
    <CategoriesDataContext.Provider value={ { categoriesData, setCategoriesData } }>
      {children}
    </CategoriesDataContext.Provider>
  </>;
}

export function useCategoriesData() {
  const { categoriesData, setCategoriesData } = useContext(CategoriesDataContext)
  return { categoriesData, setCategoriesData }
}
