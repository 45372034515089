import { createContext, useContext, useReducer } from 'react';

const MenuContext = createContext();

export default function MenuProvider({ children }) {
  const [isMenuOpened, setMenuOpened] = useReducer(
    (previousValue) => !previousValue,
    false
  )
  return (
    <MenuContext.Provider value={{isMenuOpened, setMenuOpened}}>
      {children}
    </MenuContext.Provider>
  )
}

export function useMenu(){
  const {isMenuOpened, setMenuOpened} = useContext(MenuContext)
  return {isMenuOpened, setMenuOpened}
}