import React from 'react';
import { LoadingPlaceholderContainer } from './styles';

const fakeData = {
  siteName: "Yart",
  siteLogo: "https://images.tcdn.com.br/img/img_prod/465211/1591624508_yart.png",
}

export default function LoadingPlaceholder() {
  return <>
    <LoadingPlaceholderContainer>
      <img
        src={fakeData.siteLogo}
        alt={fakeData.siteName}
        loading="lazy"
      />
    </LoadingPlaceholderContainer>
  </>;
}
