import { createContext, useContext, useState } from 'react';

const StoreDataContext = createContext();

export default function StoreDataProvider({ children }) {
  const [storeData, setStoreData] = useState({})
  return (
    <StoreDataContext.Provider value={{ storeData, setStoreData }}>
      {children}
    </StoreDataContext.Provider>
  )
}

export function useStoreData() {
  const { storeData, setStoreData } = useContext(StoreDataContext)
  return { storeData, setStoreData }
}