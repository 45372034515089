import BestSellersDataProvider from 'context/BestSellersDataContext';
import CarouselImagesProvider from 'context/CarouselImagesContext';
import HighlightsDataProvider from 'context/HighlightsDataContext';
import RulerDataProvider from 'context/RulerDataContext';
import WidgetsDataProvider from 'context/WidgetsDataContext';
import React from 'react';

export default function HomeProviderContainer({ children }) {
  return <>
    <CarouselImagesProvider>
      <RulerDataProvider>
        <WidgetsDataProvider>
          <HighlightsDataProvider>
            <BestSellersDataProvider>
              {children}
            </BestSellersDataProvider>
          </HighlightsDataProvider>
        </WidgetsDataProvider>
      </RulerDataProvider>
    </CarouselImagesProvider>
  </>;
}
