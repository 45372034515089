import { createContext, useContext, useState } from 'react';
const BestSellersDataContext = createContext();

export default function BestSellersDataProvider({ children }) {
  const [bestSellersData, setBestSellersData] = useState([])
  return <>
    <BestSellersDataContext.Provider value={ { bestSellersData, setBestSellersData } }>
      {children}
    </BestSellersDataContext.Provider>
  </>;
}

export function useBestSellersData() {
  const { bestSellersData, setBestSellersData } = useContext(BestSellersDataContext)
  return { bestSellersData, setBestSellersData }
}
