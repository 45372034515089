import { createContext, useContext, useState } from 'react';
const WidgetsDataContext = createContext();

export default function WidgetsDataProvider({ children }) {
  const [widgetsData, setWidgetsData] = useState([])
  return <>
    <WidgetsDataContext.Provider value={ { widgetsData, setWidgetsData } }>
      {children}
    </WidgetsDataContext.Provider>
  </>;
}

export function useWidgetsData() {
  const { widgetsData, setWidgetsData } = useContext(WidgetsDataContext)
  return { widgetsData, setWidgetsData }
}
