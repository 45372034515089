import { createContext, useContext, useState } from 'react';
const HighlightsDataContext = createContext();

export default function HighlightsDataProvider({ children }) {
  const [highlightsData, setHighlightsData] = useState([])
  return <>
    <HighlightsDataContext.Provider value={ { highlightsData, setHighlightsData } }>
      {children}
    </HighlightsDataContext.Provider>
  </>;
}

export function useHighlightsData() {
  const { highlightsData, setHighlightsData } = useContext(HighlightsDataContext)
  return { highlightsData, setHighlightsData }
}
