import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  25% {
    transform: rotateX(-90deg) rotateY(0deg);
  }
  75% {
    transform: rotateX(0deg) rotateY(-360deg);
  }
`
export const LoadingPlaceholderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img{
    padding: 50px;
    animation: ${rotate} 2.5s 1s infinite;
  }
`