import { createContext, useContext, useState } from 'react';
const FooterSocialDataContext = createContext();

export default function FooterSocialDataProvider({ children }) {
  const [footerSocialData, setFooterSocialData] = useState([])
  return <>
    <FooterSocialDataContext.Provider value={ { footerSocialData, setFooterSocialData } }>
      {children}
    </FooterSocialDataContext.Provider>
  </>;
}

export function useFooterSocialData() {
  const { footerSocialData, setFooterSocialData } = useContext(FooterSocialDataContext)
  return { footerSocialData, setFooterSocialData }
}
