import { createContext, useContext, useState } from 'react';
const CarouselImagesContext = createContext();

export default function CarouselImagesProvider({ children }) {
  const [carouselImages, setCarouselImages] = useState([])
  return <>
    <CarouselImagesContext.Provider value={ { carouselImages, setCarouselImages } }>
      {children}
    </CarouselImagesContext.Provider>
  </>;
}

export function useCarouselImages() {
  const { carouselImages, setCarouselImages } = useContext(CarouselImagesContext)
  return { carouselImages, setCarouselImages }
}
