import CategoriesDataProvider from 'context/CategoriesDataContext';
import FooterMenuDataProvider from 'context/FooterMenuDataContext';
import FooterMessageDataProvider from 'context/FooterMessageDataContext';
import FooterSocialDataProvider from 'context/FooterSocialDataContext';
import MenuProvider from 'context/MenuContext';
import StoreDataProvider from 'context/StoreDataContext';
import React from 'react';

export default function StaticSiteProviderContainer({ children }) {
  return <>
    <MenuProvider>
      <StoreDataProvider>
        <CategoriesDataProvider>
          <FooterMenuDataProvider>
            <FooterSocialDataProvider>
              <FooterMessageDataProvider>
                {children}
              </FooterMessageDataProvider>
            </FooterSocialDataProvider>
          </FooterMenuDataProvider>
        </CategoriesDataProvider>
      </StoreDataProvider>
    </MenuProvider>
  </>;
}
