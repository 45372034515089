import { createContext, useContext, useState } from 'react';
const RulerDataContext = createContext();

export default function RulerDataProvider({ children }) {
  const [rulerData, setRulerData] = useState([])
  return <>
    <RulerDataContext.Provider value={ { rulerData, setRulerData } }>
      {children}
    </RulerDataContext.Provider>
  </>;
}

export function useRulerData() {
  const { rulerData, setRulerData } = useContext(RulerDataContext)
  return { rulerData, setRulerData }
}
