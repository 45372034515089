import { createContext, useContext, useState } from 'react';
const FooterMenuDataContext = createContext();

export default function FooterMenuDataProvider({ children }) {
  const [footerMenuData, setFooterMenuData] = useState()
  return <>
    <FooterMenuDataContext.Provider value={ { footerMenuData, setFooterMenuData } }>
      {children}
    </FooterMenuDataContext.Provider>
  </>;
}

export function useFooterMenuData() {
  const { footerMenuData, setFooterMenuData } = useContext(FooterMenuDataContext)
  return { footerMenuData, setFooterMenuData }
}
