import React, { lazy, Suspense } from 'react';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import ScrollToTop from 'components/ScrollToTop';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import StaticSiteProviderContainer from 'components/ProviderContainer';
import HomeProviderContainer from 'components/HomeProviderContainer';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { GlobalStyle } from 'styles';
const StaticSite = lazy(() => import('components/StaticSite'));
const Home = lazy(() => import('pages/Home'));
const Product = lazy(() => import('pages/Product'));

export default function App() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingPlaceholder />}>
        <BrowserRouter>
          <ScrollToTop />
          <GlobalStyle />
          <StaticSiteProviderContainer>
            <StaticSite>
              <Switch>
                <HomeProviderContainer>
                  <Route exact path="/" component={Home} />
                </HomeProviderContainer>
                <Route path="/product" component={Product} />
              </Switch>
            </StaticSite>
          </StaticSiteProviderContainer>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
}
